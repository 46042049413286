<template>
  <modal-layout @close="$emit('close')" :title="info.title">
      <img :src="info.cover" class="banner-image">
      <section class="section">
        <h4 class="main-title">{{ info.subtitle }}</h4>
        <div class="text-section injected-html" v-html="info.content" style="text-align: justify;"></div>
      </section>
    <button @click="openForm" class="primary-btn bottom-page-btn">
      {{$t('getAPolicy')}}
    </button>
  </modal-layout>
</template>

<script>

export default {
  name: "InsuranceModal",
  data() {
    return {
      info: []
    }
  },
  mounted() {
    this.info = this.$attrs.info;
  },
  methods: {
    openForm() {
      this.$emit('close');
      this.$router.push({name: 'insurance.products', params: {id: this.info.id, form_component: this.info.form_component, }})
    }
  }
}
</script>

<style lang="scss" >

</style>
