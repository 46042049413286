<template>
  <secondary-page-layout :title="insuranceType.title" :displayedNavigation="true">
    <div class="px-3">
      <div class="cards-grid">
        <template v-if="!cardLoading">
          <template>
            <insurance-card
                @click.native="logClickEvent(product.insurance_type)"
                v-for="product in insuranceType.products"
                :product="product"
                :key="product.id"
            />
          </template>
        </template>
        <template v-else>
          <div class="image-card card" v-for="item in 2" :key="item">
            <div class="img skeleton-img skeleton"/>
            <div class="d-flex justify-content-between align-items-center p-3">
              <div class="w-50">
                <h3 class="title skeleton skeleton-text w-75"></h3>
                <h3 class="title skeleton skeleton-text w-50"></h3>
              </div>
              <div class="gray-btn skeleton w-50"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </secondary-page-layout>
</template>

<script>
import InsuranceCard from "../components/InsuranceCard";
import eventLogger from '../services/eventLogger';

export default {
  name: "InsuranceTypes",
  components: {InsuranceCard},
  data() {
    return {
      insuranceType: {},
      cardLoading: true,
    }
  },
  mounted() {
    this.getInsuranceTypes();
  },
  computed:{
    hasOneProduct(){
      return this.insuranceType?.products?.length === 1
    }
  },
  methods: {
    async getInsuranceTypes() {
      try {
        const {data} = await window.axios.get(`/insurance-types/${this.$route.params.id}`);

        if(this.$route.params.id == 1) {
          await eventLogger('sk_auto_main_screen')
        }
        let products = data.products;

        
        for (var i = 0; i < products.length; i++) {
          
         if(products[i].insurance_type == '2225'){
            products[i].form_component = 'casco-subscription-form';
          }

          if(products[i].insurance_type == "3014"){
            products[i].form_component = 'flat-subscription-form';
          }

          if(products[i].insurance_type == "3016"){
            products[i].form_component = 'house-subscription-form';
          }
        }
        
        data.products = products;
        

        this.insuranceType = data;

        if (this.hasOneProduct) {
          let [product] = products;

          await this.$router.push({ name: 'AboutProduct', params: {id: product.id, product: product}, query: {toHome: '1'}})
        }
        this.cardLoading = false;
      } catch (e) {
        throw e;
      }
    },
    logClickEvent(insuranceType) {
      let eventName;

      switch(insuranceType) {
        case '1000':
          eventName = 'select_ogpo'
          break;
        case '2220':
        case '2221':
          eventName = 'select_kasko_lite'
          break; 
        default:
          eventName = false
          break;
      }
      if(eventName) {
        eventLogger(eventName);
      }
    }
  }
}
</script>

<style scoped>
.skeleton-img {
  height: 175px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 12px;
}
</style>
